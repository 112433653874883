"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnTypesEnum = void 0;
var ColumnTypesEnum;
(function (ColumnTypesEnum) {
    ColumnTypesEnum["STRING"] = "String";
    ColumnTypesEnum["NUMBER"] = "Number";
    ColumnTypesEnum["DATE"] = "Date";
    ColumnTypesEnum["EMAIL"] = "Email";
    ColumnTypesEnum["REGEX"] = "Regex";
    ColumnTypesEnum["SELECT"] = "Select";
    ColumnTypesEnum["ANY"] = "Any";
    ColumnTypesEnum["DOUBLE"] = "Double";
})(ColumnTypesEnum = exports.ColumnTypesEnum || (exports.ColumnTypesEnum = {}));
