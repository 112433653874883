"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventTypesEnum = void 0;
var EventTypesEnum;
(function (EventTypesEnum) {
    EventTypesEnum["INIT_IFRAME"] = "INIT_IFRAME";
    EventTypesEnum["SHOW_WIDGET"] = "SHOW_WIDGET";
    EventTypesEnum["WIDGET_READY"] = "WIDGET_READY";
    EventTypesEnum["CLOSE_WIDGET"] = "CLOSE_WIDGET";
    EventTypesEnum["AUTHENTICATION_VALID"] = "AUTHENTICATION_VALID";
    EventTypesEnum["AUTHENTICATION_ERROR"] = "AUTHENTICATION_ERROR";
    EventTypesEnum["UPLOAD_STARTED"] = "UPLOAD_STARTED";
    EventTypesEnum["UPLOAD_TERMINATED"] = "UPLOAD_TERMINATED";
    EventTypesEnum["UPLOAD_COMPLETED"] = "UPLOAD_COMPLETED";
})(EventTypesEnum = exports.EventTypesEnum || (exports.EventTypesEnum = {}));
