"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiService = void 0;
const shared_1 = require("@impler/shared");
class ApiService {
    constructor(backendUrl) {
        this.backendUrl = backendUrl;
        this.isAuthenticated = false;
        this.httpClient = new shared_1.HttpClient(backendUrl);
    }
    setAuthorizationToken(token) {
        this.httpClient.setAuthorizationToken(token);
        this.isAuthenticated = true;
    }
    disposeAuthorizationToken() {
        this.httpClient.disposeAuthorizationToken();
        this.isAuthenticated = false;
    }
    checkIsRequestvalid(projectId, templateId, schema) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.post(`/common/valid`, {
                projectId,
                templateId,
                schema,
            });
        });
    }
    getImportConfig(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get(`/common/import-config?projectId=${projectId}`);
        });
    }
    getExcelSheetNames(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('file', data.file);
            return this.httpClient.post(`/common/sheet-names`, formData, {
                'Content-Type': 'multipart/form-data',
            });
        });
    }
    uploadFile(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append('file', data.file);
            if (data.authHeaderValue)
                formData.append('authHeaderValue', data.authHeaderValue);
            if (data.extra)
                formData.append('extra', data.extra);
            if (data.schema)
                formData.append('schema', data.schema);
            if (data.output)
                formData.append('output', data.output);
            if (data.selectedSheetName)
                formData.append('selectedSheetName', data.selectedSheetName);
            return this.httpClient.post(`/upload/${data.templateId}`, formData, {
                'Content-Type': 'multipart/form-data',
            });
        });
    }
    getTemplates(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get(`/project/${projectId}/templates`);
        });
    }
    getMappings(uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get(`/mapping/${uploadId}`);
        });
    }
    finalizeMappings(uploadId, mappings) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.post(`/mapping/${uploadId}/finalize`, mappings);
        });
    }
    doReivewData(uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.post(`/review/${uploadId}`);
        });
    }
    getReviewData({ uploadId, page, limit, type, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = (0, shared_1.constructQueryString)({ limit, page, type });
            return this.httpClient.get(`/review/${uploadId}${queryString}`);
        });
    }
    confirmReview(uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.post(`/review/${uploadId}/confirm`);
        });
    }
    getUpload(uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get(`/upload/${uploadId}`);
        });
    }
    terminateUpload(uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.delete(`/upload/${uploadId}`);
        });
    }
    getColumns(uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get(`/upload/${uploadId}/columns`);
        });
    }
    getValidUploadedRows(uploadId, page, limit) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get(`/upload/${uploadId}/rows/valid?page=${page}&limit=${limit}`);
        });
    }
    getInvalidUploadedRows(uploadId, page, limit) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get(`/upload/${uploadId}/rows/invalid?page=${page}&limit=${limit}`);
        });
    }
    getSignedUrl(key) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.post(`/common/signed-url`, {
                key,
            });
        });
    }
    downloadSample(templateId, data, schema) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.post(`/template/${templateId}/sample`, {
                data,
                schema,
            }, {}, 'blob');
        });
    }
    updateRecord(uploadId, record) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.put(`/review/${uploadId}/record`, record);
        });
    }
    deleteRecord(uploadId, indexes, valid, invalid) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.delete(`/review/${uploadId}/record?indexes=${indexes}&valid=${valid}&invalid=${invalid}`);
        });
    }
}
exports.ApiService = ApiService;
