"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUES_OBJ = exports.DEFAULT_VALUES_ARR = exports.DEFAULT_VALUES = exports.Defaults = void 0;
const uuid_1 = require("uuid");
exports.Defaults = {
    ONE: 1,
    PAGE_LIMIT: 100,
    ZERO: 0,
    MINUS_ONE: -1,
    NOT_FOUND_STATUS_CODE: 404,
    READY_STATE: 1,
    CHUNK_SIZE: 100,
    DATE_FORMATS: ['DD/MM/YYYY'],
    DATE_FORMAT: 'DD/MM/YYYY',
};
exports.DEFAULT_VALUES = [
    {
        label: 'null',
        value: '<<null>>',
    },
    {
        label: 'undefined',
        value: '<<undefined>>',
    },
    {
        label: 'Empty String',
        value: '<<>>',
    },
    {
        label: 'Empty Array ([])',
        value: '<<[]>>',
    },
    {
        label: 'Boolean true',
        value: '<<true>>',
    },
    {
        label: 'Boolean false',
        value: '<<false>>',
    },
    {
        label: 'UUID v4',
        value: '<<uuid>>',
    },
];
exports.DEFAULT_VALUES_ARR = exports.DEFAULT_VALUES.reduce((acc, item) => {
    acc.push(item.value);
    return acc;
}, []);
exports.DEFAULT_VALUES_OBJ = {
    '<<null>>': null,
    '<<undefined>>': undefined,
    '<<>>': '',
    '<<[]>>': [],
    '<<true>>': true,
    '<<false>>': false,
    '<<uuid>>': uuid_1.v4,
};
