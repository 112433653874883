"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BubbleBaseService = void 0;
const destination_types_1 = require("../../types/destination/destination.types");
class BubbleBaseService {
    createBubbleIoUrl(data, type = 'single') {
        let url = data.customDomainName ? `https://${data.customDomainName}` : `https://${data.appName}.bubbleapps.io`;
        if (data.environment === destination_types_1.BubbleDestinationEnvironmentEnum.DEVELOPMENT)
            url += '/version-test';
        return `${url}/api/1.1/obj/${data.datatype}${type === 'bulk' ? '/bulk' : ''}`;
    }
    throwRequestError(errorWithType) {
        var _a, _b;
        if (errorWithType.response) {
            const response = errorWithType.response.data;
            if (response === null || response === void 0 ? void 0 : response.translation)
                throw new Error(response === null || response === void 0 ? void 0 : response.translation);
            else if ((_a = response.body) === null || _a === void 0 ? void 0 : _a.message)
                throw new Error((_b = response.body) === null || _b === void 0 ? void 0 : _b.message);
            else if (response.includes('invalid appname hosted on bubbleapps.io'))
                throw new Error('Invalid App Name');
            else if (errorWithType.response.status === 401)
                throw new Error(`You're not authorized to access this app. Please check "App Name" or "API Private Key"`);
            console.log('response error', response, errorWithType.response);
        }
        else if (errorWithType.request) {
            if (errorWithType.message.includes('ECONNRESET'))
                throw new Error('Cannot connect to app');
            if (errorWithType.message.includes('getaddrinfo'))
                throw new Error('Cannot connect to app. Please check "App Name" or "CustomDomain Name"');
            console.log('request error', errorWithType.message, errorWithType.request);
        }
        else {
            console.log('unknown error', errorWithType.message);
        }
        throw new Error(errorWithType.message);
    }
}
exports.BubbleBaseService = BubbleBaseService;
