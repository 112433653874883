"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENVTypesEnum = exports.ACCESS_KEY_NAME = void 0;
exports.ACCESS_KEY_NAME = 'accesskey';
var ENVTypesEnum;
(function (ENVTypesEnum) {
    ENVTypesEnum["DEV"] = "dev";
    ENVTypesEnum["TEST"] = "test";
    ENVTypesEnum["PROD"] = "prod";
    ENVTypesEnum["CI"] = "ci";
    ENVTypesEnum["LOCAL"] = "local";
})(ENVTypesEnum = exports.ENVTypesEnum || (exports.ENVTypesEnum = {}));
