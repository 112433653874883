"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContextPath = exports.ImplerComponentEnum = void 0;
var ImplerComponentEnum;
(function (ImplerComponentEnum) {
    ImplerComponentEnum[ImplerComponentEnum["WEB"] = 0] = "WEB";
    ImplerComponentEnum[ImplerComponentEnum["API"] = 1] = "API";
    ImplerComponentEnum[ImplerComponentEnum["WIDGET"] = 2] = "WIDGET";
})(ImplerComponentEnum = exports.ImplerComponentEnum || (exports.ImplerComponentEnum = {}));
function getContextPath(component) {
    let contextPath = '';
    if (process.env.GLOBAL_CONTEXT_PATH) {
        contextPath += process.env.GLOBAL_CONTEXT_PATH + '/';
    }
    switch (component) {
        case ImplerComponentEnum.API:
            if (process.env.API_CONTEXT_PATH) {
                contextPath += process.env.API_CONTEXT_PATH + '/';
            }
            break;
        case ImplerComponentEnum.WEB:
            if (process.env.WEB_CONTEXT_PATH) {
                contextPath += process.env.WEB_CONTEXT_PATH + '/';
            }
            break;
        case ImplerComponentEnum.WIDGET:
            if (process.env.WIDGET_CONTEXT_PATH) {
                contextPath += process.env.WIDGET_CONTEXT_PATH + '/';
            }
            break;
    }
    return contextPath;
}
exports.getContextPath = getContextPath;
