"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileNameService = void 0;
class FileNameService {
    getURLOrigin() {
        const url = new URL(process.env.S3_LOCAL_STACK);
        return url.origin;
    }
    getSampleFileName(templateId, hasMultiSelect) {
        return `${templateId}/sample.${hasMultiSelect ? 'xlsm' : 'xlsx'}`;
    }
    getSampleFileUrl(templateId, hasMultiSelect) {
        const fileName = this.getSampleFileName(templateId, hasMultiSelect);
        const origin = this.getURLOrigin();
        return [origin, process.env.S3_BUCKET_NAME, fileName].join('/');
    }
    getFileExtension(fileName) {
        return fileName.split('.').pop();
    }
    getOriginalFileName(fileName) {
        const filenameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
        const random = Math.random().toString(36).slice(2);
        return `${filenameWithoutExtension}-${random}.${this.getFileExtension(fileName)}`;
    }
    getOriginalFilePath(uploadId, fileName) {
        return `${uploadId}/${fileName}`;
    }
    getUploadedFilePath(uploadId, fileName) {
        return `${uploadId}/${this.getUploadedFileName(fileName)}`;
    }
    getUploadedFileName(fileName) {
        return `uploaded.${this.getFileExtension(fileName)}`;
    }
    getAllJsonDataFileName() {
        return `all-data.json`;
    }
    getAllJsonDataFilePath(uploadId) {
        return `${uploadId}/${this.getAllJsonDataFileName()}`;
    }
    getAllCSVDataFileName() {
        return `all-data.csv`;
    }
    getAllCSVDataFilePath(uploadId) {
        return `${uploadId}/${this.getAllCSVDataFileName()}`;
    }
    getInvalidDataFileName() {
        return `invalid-data.json`;
    }
    getInvalidDataFilePath(uploadId) {
        return `${uploadId}/${this.getInvalidDataFileName()}`;
    }
    getInvalidExcelDataFileName() {
        return 'invalid-data.xlsx';
    }
    getInvalidExcelDataFilePath(uploadId) {
        return `${uploadId}/${this.getInvalidExcelDataFileName()}`;
    }
    getInvalidExcelDataFileUrl(uploadId) {
        const path = this.getInvalidExcelDataFilePath(uploadId);
        const origin = this.getURLOrigin();
        return [origin, process.env.S3_BUCKET_NAME, path].join('/');
    }
    getValidDataFileName() {
        return `valid-data.json`;
    }
    getValidDataFilePath(uploadId) {
        return `${uploadId}/${this.getValidDataFileName()}`;
    }
}
exports.FileNameService = FileNameService;
