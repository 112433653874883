"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueuesEnum = exports.FileEncodingsEnum = exports.FileMimeTypesEnum = exports.SupportedFileMimeTypes = exports.UploadStatusEnum = void 0;
var UploadStatusEnum;
(function (UploadStatusEnum) {
    UploadStatusEnum["UPLOADED"] = "Uploaded";
    UploadStatusEnum["MAPPING"] = "Mapping";
    UploadStatusEnum["MAPPED"] = "Mapped";
    UploadStatusEnum["REVIEWING"] = "Reviewing";
    UploadStatusEnum["REVIEWED"] = "Reviewed";
    UploadStatusEnum["CONFIRMED"] = "Confirmed";
    UploadStatusEnum["PROCESSING"] = "Processing";
    UploadStatusEnum["COMPLETED"] = "Completed";
    UploadStatusEnum["TERMINATED"] = "Terminated";
})(UploadStatusEnum = exports.UploadStatusEnum || (exports.UploadStatusEnum = {}));
exports.SupportedFileMimeTypes = [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroenabled.12',
];
var FileMimeTypesEnum;
(function (FileMimeTypesEnum) {
    FileMimeTypesEnum["CSV"] = "text/csv";
    FileMimeTypesEnum["EXCEL"] = "application/vnd.ms-excel";
    FileMimeTypesEnum["EXCELX"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    FileMimeTypesEnum["EXCELM"] = "application/vnd.ms-excel.sheet.macroenabled.12";
    FileMimeTypesEnum["JSON"] = "application/json";
})(FileMimeTypesEnum = exports.FileMimeTypesEnum || (exports.FileMimeTypesEnum = {}));
var FileEncodingsEnum;
(function (FileEncodingsEnum) {
    FileEncodingsEnum["CSV"] = "utf8";
    FileEncodingsEnum["EXCEL"] = "base64";
    FileEncodingsEnum["EXCELX"] = "base64";
    FileEncodingsEnum["JSON"] = "utf8";
})(FileEncodingsEnum = exports.FileEncodingsEnum || (exports.FileEncodingsEnum = {}));
var QueuesEnum;
(function (QueuesEnum) {
    QueuesEnum["SEND_WEBHOOK_DATA"] = "SEND_WEBHOOK_DATA";
    QueuesEnum["SEND_BUBBLE_DATA"] = "SEND_BUBBLE_DATA";
    QueuesEnum["END_IMPORT"] = "END_IMPORT";
})(QueuesEnum = exports.QueuesEnum || (exports.QueuesEnum = {}));
